import { defineStore } from "pinia";
import { useStorage } from "vue3-storage";
import { ref, watch, computed, onMounted } from "vue";
import { get } from "core-js/core/dict";

const storage = useStorage();

export const useAuthStore = defineStore("auth", () => {
    const token = ref(storage.getStorageSync("authHeader"));


    watch(token, (value) => {
        if (value) {
            storage.setStorage({key:'authHeader',data:value});
            storage.setStorageSync("authHeader", value);
        } else {
            storage.removeStorage({key:'authHeader'});
        }
    });
    const getToken = () => {
        return token.value;
    };
    const setToken = (value) => {
        token.value = value;
    };

    const jwtToken = computed(() => {
        if (!token.value) {
            return null;
        }

        return token.value.replace('Bearer ','');
    });
    
    const payload = computed(() => {
        if (!jwtToken.value) {
            return null;
        }
        return JSON.parse(atob(jwtToken.value.split('.')[1]))
    });

    const getPayload = () => {
        return payload.value;
    };

    const expired = computed(() => {
        if (!payload.value) {
            return true;
        }
        return Date.now() >= payload.value.exp * 1000;
    });

    const isLogged = () => {
        if(!payload.value){
            return false;
        }
        if(payload.value.email){
            return true;
        }
        return false;
    }

    const isExpired = () => {
        return expired.value;
    };

    return { getToken, setToken, isExpired, getPayload, isLogged };
});
<script setup>
import 'vue3-carousel/carousel.css';
import { watch } from 'vue';
import { useHead } from '@vueuse/head';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const route = useRoute();
const { locale, availableLocales } = useI18n();

const updateHead = () => {
  const links = availableLocales.map((lang) => {
    if(!route.name || route.name === undefined) return;
    if(route.name == '404') return;
    const routeName = `${lang}.${route.name.split('.')[1]}`;
    // we check if the route exists in the router
    if (!router.hasRoute(routeName)) return;
    const url = router.resolve({ name: routeName, params: route.params, query: route.query }).href;
    return { rel: 'alternate', hreflang: lang, href: url };
  });
  // we need to remove the previous link alternates
  document.querySelectorAll('link[rel="alternate"]').forEach((el) => el.remove());
  useHead({
    link: links,
  });
  // we set the lang attribute on the html tag
  document.documentElement.lang = locale.value;
};
// Watch for route changes and update the head
watch([route, locale], updateHead, { immediate: true });
</script>

<template>
    <router-view></router-view>
</template>
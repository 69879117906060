import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles'

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

export default createVuetify({
    components,
    directives,
    theme:{
      defaultTheme: 'light',
      themes:{
        light: {
          name: 'light',
          dark: false,
          colors: {
            primary: '#1976D2',
            secondary: '#424242',
            accent: '#82B1FF',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
          },
          selectItemBackground: '#FFFFFF', // Custom background color for select items
          selectItemText: '#000000', // Custom text color for select items
          selectItemHoverBackground: '#F5F5F5', // Custom hover background color for select items
        },
      }
    },
    defaults: {
        VBtn: {},
        VCard: {
          rounded: 'md'
        },
        VTextField: {
          rounded: 'lg'
        },
        VTooltip: {
          // set v-tooltip default location to top
          location: 'top'
        }
    },
    
});
/**
 * plugin/index.ts
 * Automatically included in `src/main.ts`
 */

// Plugins
import vuetify from './vuetify'
import pinia from '@/stores'
import router from '@/router'
import { markRaw } from 'vue'
import { createHead } from '@vueuse/head'
import Vue3Storage from "vue3-storage";
import { vMaska } from 'maska/vue';
import i18n from './i18n';
import messages from '@/locales/messages';
import Vue3Marquee from 'vue3-marquee'; 
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import gtmPlugin from './gtm'
import * as Sentry from "@sentry/vue";



pinia.use(({ store }) => {
    store.$router = markRaw(router)
});

const head = createHead()

export function registerPlugins(app: any){
    Sentry.init({
        app,
        dsn: "https://98852f80c5a4c130ed839895628a69e2@o4509068424970240.ingest.de.sentry.io/4509068426281040",
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration()
        ],
        // Tracing
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost",/^http:\/\/localhost:5173/, /^https:\/\/dev.kasefilters\.fr/, /^https:\/\/www.kasefilters\.fr/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    app.use(vuetify)
    app.use(pinia)
    app.use(router)
    app.use(head)
    app.use(i18n)
    app.use(Vue3Storage, {
        prefix: 'kase_'
    })
    app.use(gtmPlugin,{ router })
    app.use(Vue3Marquee)
    app.use(Toast, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
    });
    app.directive('maska', vMaska)
}
import fr from './fr.json';
import en from './en.json';
import es from './es.json';
import pt from './pt.json';
import it from './it.json';
import de from './de.json';

const messages = {
    "fr": fr,
    "en": en,
    "es": es,
    "pt": pt,
    "it": it,
    "de": de
};
export default messages;
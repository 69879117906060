export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/index.vue'),
    props: true,
    /* no children */
    meta: {
      "title": "KaseFilter Home",
      "layout": "default",
      "middleware": "guest"
    }
  },
  {
    path: '/about-kase',
    name: 'aboutkase',
    component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/about-kase.vue'),
    props: true,
    /* no children */
    meta: {
      "title": "A propos de KaseFilters",
      "layout": "default",
      "description": "Découvrez l'histoire de KaseFilters",
      "middleware": "guest"
    }
  },
  {
    path: '/about-us',
    name: 'aboutus',
    component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/about-us.vue'),
    props: true,
    /* no children */
    meta: {
      "title": "A propos de KaseFilters France",
      "layout": "default",
      "description": "Découvrez KaseFilters France",
      "middleware": "guest"
    }
  },
  {
    path: '/account',
    /* internal name: '/account' */
    /* no component */
    children: [
      {
        path: '',
        name: 'account.orders',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/account/index.vue'),
        /* no children */
        meta: {
          "title": "Votre Compte Kase",
          "layout": "default",
          "middleware": "auth",
          "auth_redirect": "account.login"
        }
      },
      {
        path: 'login',
        name: 'account.login',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/account/login.vue'),
        /* no children */
        meta: {
          "title": "M'identifier - Kase",
          "layout": "default",
          "middleware": "guest"
        }
      },
      {
        path: 'lost',
        name: 'account.lost',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/account/lost.vue'),
        /* no children */
        meta: {
          "title": "J'ai oublié mon mot de passe - Kase",
          "layout": "default",
          "middleware": "guest"
        }
      },
      {
        path: 'my_info',
        name: 'account',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/account/my_info.vue'),
        /* no children */
        meta: {
          "title": "Votre Compte Kase",
          "layout": "default",
          "middleware": "auth",
          "auth_redirect": "account.login"
        }
      }
    ],
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/cart.vue'),
    /* no children */
    meta: {
      "title": "Panier - KaseFilters",
      "layout": "default",
      "description": "Votre panier sur KaseFilters",
      "middleware": "guest"
    }
  },
  {
    path: '/category',
    /* internal name: '/category' */
    /* no component */
    children: [
      {
        path: ':cats+',
        name: 'category_path',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/category/[cats+].vue'),
        props: true,
        /* no children */
        meta: {
          "title": "Category Kase",
          "layout": "default",
          "middleware": "guest"
        }
      },
      {
        path: ':name()_:cat_id',
        name: 'category',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/category/[name]_[cat_id].vue'),
        props: true,
        /* no children */
        meta: {
          "title": "Category Kase",
          "layout": "default",
          "middleware": "guest"
        }
      },
      {
        path: 'favorites',
        name: 'category_favorites',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/category/favorites.vue'),
        props: true,
        /* no children */
        meta: {
          "title": "Favorites Kase",
          "layout": "default",
          "middleware": "guest"
        }
      },
      {
        path: 'sales',
        name: 'category_sales',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/category/sales.vue'),
        props: true,
        /* no children */
        meta: {
          "title": "Category Kase",
          "layout": "default",
          "middleware": "guest"
        }
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/category/search.vue'),
        props: true,
        /* no children */
        meta: {
          "title": "Search Kase",
          "layout": "default",
          "middleware": "guest"
        }
      }
    ],
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/contact.vue'),
    props: true,
    /* no children */
    meta: {
      "title": "Contacter KaseFilters France",
      "layout": "default",
      "description": "Comment contacter KaseFilters France",
      "middleware": "guest"
    }
  },
  {
    path: '/order',
    /* internal name: '/order' */
    /* no component */
    children: [
      {
        path: '',
        name: 'order',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/order/index.vue'),
        /* no children */
        meta: {
          "title": "Passer commande - Kase",
          "layout": "default",
          "middleware": "auth"
        }
      },
      {
        path: ':order_id',
        name: 'order.order_id',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/order/[order_id].vue'),
        props: true,
        /* no children */
        meta: {
          "title": "Récapitulatif de la commande",
          "layout": "default",
          "middleware": "guest"
        }
      }
    ],
  },
  {
    path: '/product',
    /* internal name: '/product' */
    /* no component */
    children: [
      {
        path: ':slug',
        name: 'product',
        component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/product/[slug].vue'),
        props: true,
        /* no children */
        meta: {
          "title": "Produit KaseFilters",
          "layout": "default",
          "description": "Découvrez ce produit KaseFilters",
          "middleware": "guest"
        }
      }
    ],
  },
  {
    path: '/return',
    name: 'return',
    component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/return.vue'),
    props: true,
    /* no children */
    meta: {
      "title": "Information sur les retours et remboursement KaseFilters France",
      "layout": "default",
      "description": "Que faire si ma commande ne me convient pas ou est endommagée ? Comment retourner un produit KaseFilters acheté auprès de KaseFilters France ?",
      "middleware": "guest"
    }
  },
  {
    path: '/secure',
    name: 'secure',
    component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/secure.vue'),
    /* no children */
    meta: {
      "title": "Secure Paiement - Kase",
      "layout": "default"
    }
  },
  {
    path: '/shipping',
    name: 'shipping',
    component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/shipping.vue'),
    props: true,
    /* no children */
    meta: {
      "title": "Information de livraison KaseFilters France",
      "layout": "default",
      "description": "Quel mode de livraison, quel délai et quel coût pour votre commande KaseFilters",
      "middleware": "guest"
    }
  },
  {
    path: '/tcs',
    name: 'tcs',
    component: () => import('/Users/simon/Sites/_git/kase2022/src/pages/tcs.vue'),
    /* no children */
    meta: {
      "title": "CGV - Kase",
      "layout": "default"
    }
  }
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}


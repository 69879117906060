import { createI18n } from 'vue-i18n';
import messages from '@/locales/messages';

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    fallbackLocale: 'fr',
    locale: 'fr',
    
    messages: messages,
    silentTranslationWarn: true,
    silentFallbackWarn: true
});

export default i18n
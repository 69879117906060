export default {
    install(app: any, options: any) {
      app.config.globalProperties.$gtm = {
        pushEvent(event: string, data: any) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event,
            ...data
          })
        }
      }
  
      if (options?.router) {
        options.router.afterEach((to: any) => {
          // Push page view event to dataLayer
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'page_view',
            page_path: to.path,
            page_title: document.title
          })
        })
      }
    }
}